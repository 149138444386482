import Vue from "vue";

/*TITLE*/
document.title = "Residencial Torreta de Moraira | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Torreta de Moraira";
Vue.prototype.$subtitle = "Bienvenido a tu nuevo hogar";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Torreta de Moraira";
Vue.prototype.$introSubtitle = "Bienvenido a tu nuevo hogar";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "bottega-antracita--20230126110116.jpg";
Vue.prototype.$image_kitchen = "cocina-pavimento-bottega-white-frentes-cool-encimera-krion-white--20230126110156.jpg";
Vue.prototype.$image_bath1 = "banop-pavimento-forest-acero-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126110147.jpg";
Vue.prototype.$image_bath2 = "aseo-pavimento-forest-acero-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126120144.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "bottega-antracita--20230126110116.jpg",
  },
  {
    src: "bottega-acero--20230126110125.jpg",
  },
  {
    src: "bottega-caliza--20230126110133.jpg",
  },
  {
    src: "bottega-white--20230126110143.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-pavimento-bottega-white-frentes-cool-encimera-krion-white--20230126110156.jpg",
  },
  {
    src: "cocina-pavimento-bottega-acero-frentes-zinc-encimera-krion-white--20230126110106.jpg",
  },
  {
    src: "cocina-pavimento-bottega-caliza-frentes-bison-encimera-krion-white--20230126110114.jpg",
  },
  {
    src: "cocina-pavimento-bottega-antracita-frentes-blanco-nubol-encimera-krion-white--20230126110122.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-pavimento-forest-acero-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126110147.jpg",
  },
  {
    src: "banop-pavimento-forest-natural-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126110118.jpg",
  },
  {
    src: "banop-pavimento-forest-arce-revestimiento-base-bottega-caliza-revestimiento-decorado-spiga-bottega-caliza--20230126110131.jpg",
  },
  {
    src: "banop-pavimento-forest-cognac-revestimiento-base-bottega-caliza-revestimiento-decorado-spiga-bottega-caliza--20230126110159.jpg",
  },
  {
    src: "banop-pavimento-bottega-antracita-revestimiento-base-bottega-antracita-revestimiento-decorado-twin-bottega-acero--20230126120127.jpg",
  },
  {
    src: "banop-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorado-twin-bottega-acero--20230126120140.jpg",
  },
  {
    src: "banop-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorado-twin-bottega-caliza--20230126120120.jpg",
  },
  {
    src: "banop-pavimento-bottega-white-revestimiento-base-bottega-white-revestimiento-decorado-twin-bottega-acero--20230126120130.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "aseo-pavimento-forest-acero-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126120144.jpg",
  },
  {
    src: "aseo-pavimento-forest-cognac-revestimiento-base-bottega-caliza-revestimiento-decorado-spiga-bottega-caliza--20230126120156.jpg",
  },
  {
    src: "aseo-pavimento-forest-natural-revestimiento-base-bottega-acero-revestimiento-decorado-spiga-bottega-acero--20230126120103.jpg",
  },
  {
    src: "aseo-pavimento-forest-arce-revestimiento-base-bottega-caliza-revestimiento-decorado-spiga-bottega-caliza--20230126120112.jpg",
  },
  {
    src: "aseo-pavimento-bottega-antracita-revestimiento-base-bottega-antracita-revestimiento-decorado-twin-bottega-acero--20230126120119.jpg",
  },
  {
    src: "aseo-pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorado-twin-bottega-acero--20230126120125.jpg",
  },
  {
    src: "aseo-pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorado-twin-bottega-caliza--20230126120132.jpg",
  },
  {
    src: "aseo-pavimento-bottega-white-revestimiento-base-bottega-white-revestimiento-decorado-twin-bottega-acero--20230126120139.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/RESIDENCIAL_TORRETA_DE_MORAIRA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/2nMlkA0pVTo";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20beniatjar%208%20teulada%20moraira%20alicante%2003724",
    text: "Calle Beniatjar, 8. Teulada-Moraira, Alicante, 03724",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=plaza%20de%20la%20constitucion%201bajo%20dcha%20teulada",
    text: "Plaza de la Constitucion 1bajo-dcha. - Teulada",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:619838121",
    text: "619838121",
  },
  {
    icon: "mdi-email",
    link: "mailto:comercial@mrmarti.es",
    text: "comercial@mrmarti.es",
  },
];
